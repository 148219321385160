import { memo, useState } from "react";

import MobileNav from "./MobileNav";

import { MenuIcon } from "assets/icons";

import "./MobileMenu.css";

const MobileMenu = memo(() => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="burger_menu">
      <button className="burger_menu_btn" type="button" onClick={showDrawer}>
        <MenuIcon
          className="navbar_icon"
          style={{ color: "white", fontSize: "40px" }}
        />
      </button>
      <MobileNav onClose={onClose} visible={visible} />
    </div>
  );
});

export default MobileMenu;
