import { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import DesktopMenu from "./DesktopMenu/DesktopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";
import CustomNavLink from "components/Buttons/CustomNavLink";

import { useScroll, useNav } from "hooks";

import { LogoWithPolice } from "assets/svg";

import "./Navbar.css";

const NavBar = memo(() => {
  const { scrollY } = useScroll();
  const { currentPageData } = useNav();
  const location = useLocation().pathname;

  return (
    <nav className="navbar">
      <div
        className={`navbar_container ${
          scrollY === 0
            ? "navbar_container_scrollTop"
            : "navbar_container_scrollRest"
        }`}
        style={
          scrollY === 0 && location === "/"
            ? {
                backgroundColor: "transparent",
                boxShadow: "none",
              }
            : {}
        }
      >
        <div
          className="navbar_logo"
          style={
            scrollY === 0
              ? {
                  maxWidth: "130px",
                  backgroundColor: "white",
                  borderRadius: "50% / 50%",
                }
              : { maxWidth: "65px" }
          }
        >
          <NavLink
            to="/"
            style={{
              visibility: "visible",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoWithPolice policeColor="black" />
          </NavLink>
        </div>
        <div
          className="navbar_title"
          style={
            // mobile: we show current page title when user scrolls
            scrollY === 0 ? { display: "none" } : {}
          }
        >
          <span
            style={{
              marginRight: "75px",
              fontSize: "1.5em",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {currentPageData.title}
          </span>
        </div>
        <div className="navbar_menu_container">
          <DesktopMenu />
          <MobileMenu />
        </div>
        <div className="navbar_contact">
          <CustomNavLink path="/contact" label="Contact" />
        </div>
      </div>
    </nav>
  );
});

export default NavBar;
