import { TailSpin } from "react-loader-spinner";

const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "var(--mainBgColor)",
    }}
  >
    <TailSpin color="#00BFFF" height={80} width={80} />
  </div>
);

export default LoadingSpinner;
