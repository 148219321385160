import { NavLink } from "react-router-dom";
import { Menu } from "antd";

import { tNavDataItemChild, tNavDataItemWithChildren } from "types";

const SubMenu = (children: tNavDataItemWithChildren[]) => {
  const items = children.map((child: tNavDataItemChild) => ({
    label: <NavLink to={child.path}>{child.title}</NavLink>,
    key: child.key,
  }));
  return <Menu items={items} />;
};

export default SubMenu;
