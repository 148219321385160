import { useEffect } from "react";
import { useNotif } from "hooks";

import Router from "Router";
import NavBar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";

import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const App = () => {
  const { openCookiesNotif, isOpen } = useNotif();

  useEffect(() => {
    !isOpen && openCookiesNotif();
  }, [openCookiesNotif, isOpen]);

  return (
    <div className="App">
      <NavBar />
      <main>
        <Router />
      </main>
      <Footer />
    </div>
  );
};

export default App;
