import { NavLink } from "react-router-dom";

import { LinkedInLogo } from "assets/icons";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <section>
        <h4 className="global_hidden_title">Informations légales</h4>
        <NavLink
          to="/mentions-legales-et-politique-de-confidentialite"
          className="footer_link"
        >
          Mentions légales et politique de confidentialité
        </NavLink>
      </section>
      <section className="footer_socials">
        <h4 className="global_hidden_title">Réseaux sociaux</h4>
        <span>Retrouvez-nous sur les réseaux sociaux</span>
        <a
          href="https://www.linkedin.com/in/magali-troyes-b4b540a9/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInLogo style={{ color: "white" }} />
        </a>
      </section>
    </footer>
  );
};

export default Footer;
