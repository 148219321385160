import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import LoadingSpinner from "components/LoadingSpinner";

const Home = lazy(() => import("views/Home/Home"));

// SOLUTIONS
const Tpe = lazy(() => import("views/Solutions/Tpe"));
const Pme = lazy(() => import("views/Solutions/Pme"));
const Expert = lazy(() => import("views/Solutions/Expert"));
const Lawyer = lazy(() => import("views/Solutions/Lawyer"));

// PAY
const Externalization = lazy(() => import("views/Pay/Externalization"));
const InternalManagement = lazy(() => import("views/Pay/InternalManagement"));
const PayAudit = lazy(() => import("views/Pay/Audit"));

// HR
const HrInternalManagement = lazy(() => import("views/Hr/InternalManagement"));
const HrAudit = lazy(() => import("views/Hr/Audit"));

//COUNSEL
const TrainingCourse = lazy(() => import("views/Counsel/TrainingCourse"));
const URSSAF = lazy(() => import("views/Counsel/Urssaf"));

// ABOUT
const About = lazy(() => import("views/About/About"));
const Philosophy = lazy(() => import("views/About/Philosophy"));

// CONTACT
const Contact = lazy(() => import("views/Contact/Contact"));

// FAQ
const FAQ = lazy(() => import("views/FAQ/FAQ"));
const Pricing = lazy(() => import("views/FAQ/Pricing"));

// ALERTS
const NotFound = lazy(() => import("views/NotFound"));

// LEGAL
const LegalNotice = lazy(() => import("views/Legal/LegalNotice"));

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Home />
          </Suspense>
        }
      />

      <Route
        path="/nos-solutions/tpe"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Tpe />
          </Suspense>
        }
      />
      <Route
        path="/nos-solutions/pme"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Pme />
          </Suspense>
        }
      />
      <Route
        path="/nos-solutions/expert-comptable"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Expert />
          </Suspense>
        }
      />
      <Route
        path="/nos-solutions/cabinet-juridique"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Lawyer />
          </Suspense>
        }
      />

      <Route
        path="/paie/externalisation"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Externalization />
          </Suspense>
        }
      />
      <Route
        path="/paie/gestion-interne"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <InternalManagement />
          </Suspense>
        }
      />
      <Route
        path="/paie/audit"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <PayAudit />
          </Suspense>
        }
      />

      <Route
        path="/rh/gestion-interne-rh"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <HrInternalManagement />
          </Suspense>
        }
      />
      <Route
        path="/rh/audit"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <HrAudit />
          </Suspense>
        }
      />

      <Route
        path="/conseil-et-accompagnement/formation"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <TrainingCourse />
          </Suspense>
        }
      />
      <Route
        path="/conseil-et-accompagnement/URSSAF"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <URSSAF />
          </Suspense>
        }
      />

      <Route
        path="/qui-sommes-nous/equipe-4A"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <About />
          </Suspense>
        }
      />
      <Route
        path="/qui-sommes-nous/les-valeurs-4A"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Philosophy />
          </Suspense>
        }
      />

      <Route
        path="/contact"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Contact />
          </Suspense>
        }
      />

      <Route
        path="/faq/questions-frequentes"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <FAQ />
          </Suspense>
        }
      />
      <Route
        path="/faq/tarifs"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Pricing />
          </Suspense>
        }
      />

      <Route
        path="/mentions-legales-et-politique-de-confidentialite"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <LegalNotice />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
