import { useEffect, useState, useCallback } from "react";

import { useLocation } from "react-router-dom";

import navData from "data/navData";

const useNav = () => {
  const location = useLocation().pathname;
  const { home, solutions, pay, hr, counsel, about, faq, contact, legal } =
    navData;

  const [currentPageData, setCurrentPageData] = useState<{
    title: string;
    extTitle: string | undefined;
  }>({
    title: "4A",
    extTitle: "Société d'externalisation de la paie et conseil RH",
  });

  // Get page title based on current location path
  const getCurrentPageData = useCallback(() => {
    // Check direct paths first like "/contact"
    if (location === "/") {
      return home;
    }

    if (location.includes(legal.path)) {
      return legal;
    }

    if (location.includes(contact.path)) {
      return contact;
    }

    // Check complex paths
    let pageData;
    // Check if current path exists
    try {
      pageData = [solutions, pay, hr, counsel, about, faq]
        .filter((item) => location.includes(item.path))[0]
        .children.filter((child) => location.includes(child.path))[0];
    } catch {
      pageData = undefined;
    }

    // If current path exists return data
    if (!!pageData) {
      return pageData;
    }
    // If current path doesnt exist return 404
    return {
      title: "Oups !",
      extTitle: "La page que vous recherchez semble introuvable...",
    };
  }, [home, solutions, pay, hr, counsel, about, contact, faq, legal, location]);

  // Update state when hook is called
  useEffect(() => {
    const pageData = getCurrentPageData();
    setCurrentPageData({
      title: pageData.title,
      extTitle: pageData.extTitle,
    });
  }, [getCurrentPageData]);

  return { currentPageData };
};

export default useNav;
