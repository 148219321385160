import { NavLink, useLocation } from "react-router-dom";

const switchColor = (
  isActive: boolean,
  isTopScreen: boolean,
  location: string
) => {
  if (isActive) {
    return "var(--mainBlue)";
  }

  if (isTopScreen && location === "/") {
    return "white";
  }

  return "var(--mainGrey)";
};

const NavBtn = ({ item, isTopScreen }: { item: any; isTopScreen: boolean }) => {
  const location = useLocation().pathname;
  const isActive = location === item.path;

  return (
    <li>
      <NavLink
        className="navbar_button"
        to={item.path}
        style={{
          color: switchColor(isActive, isTopScreen, location),
          textDecoration: isActive ? "underline" : "none",
        }}
      >
        {item.title}
      </NavLink>
    </li>
  );
};

export default NavBtn;
