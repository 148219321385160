import CustomNavLink from "./CustomNavLink";

const CustomNavLinkWrapper = (props: {
  path: string;
  label: string;
  className?: string;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "100px 0 100px 0",
      }}
    >
      <CustomNavLink {...props} />
    </div>
  );
};

export default CustomNavLinkWrapper;
