import { CSSProperties } from "react";
import { NavLink } from "react-router-dom";

const CustomNavLink = ({
  path,
  label,
  className,
  style,
  color = "orange",
}: {
  path: string;
  label: string;
  className?: string;
  color?: "orange" | "blue";
  style?: CSSProperties;
}) => {
  return (
    <NavLink to={path}>
      <div
        className={`global_custom_link global_custom_btn global_${color}_btn ${className}`}
        style={style}
      >
        <div
          style={{
            height: "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "10px",
          }}
        >
          <span>{label}</span>
        </div>
      </div>
    </NavLink>
  );
};

export default CustomNavLink;
