import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Drawer, Menu } from "antd";

import CustomNavLinkWrapper from "components/Buttons/CustomNavLinkWrapper";

import navData from "data/navData";

const { SubMenu, Item } = Menu;

const MobileNavMenu = ({ onClose, visible }: any) => {
  const [openKey, setOpenKey] = useState<string>("");
  const { home, solutions, pay, hr, counsel, about, faq, legal } = navData;

  return (
    <Drawer
      width="250px"
      title="4A Paie & RH Conseils"
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <Menu mode="inline" openKeys={[openKey]}>
        <Item key={home.key} onClick={() => setOpenKey(home.key)}>
          <NavLink to={home.path} onClick={onClose}>
            {home.title}
          </NavLink>
        </Item>
        {[solutions, pay, hr, counsel, about, faq].map((item) => (
          <SubMenu
            key={item.key}
            title={item.title}
            onTitleClick={() =>
              openKey === item.key ? setOpenKey("") : setOpenKey(item.key)
            }
          >
            {item.children.map((child) => (
              <Item key={child.title}>
                <NavLink to={child.path} onClick={onClose}>
                  {child.title}
                </NavLink>
              </Item>
            ))}
          </SubMenu>
        ))}
        <Item key={legal.key} onClick={() => setOpenKey(legal.key)}>
          <NavLink to={legal.path} onClick={onClose}>
            {legal.title}
          </NavLink>
        </Item>
        <Item key="mobile_close_btn" onClick={onClose}>
          <CustomNavLinkWrapper path="/contact" label="Contact" />
        </Item>
      </Menu>
    </Drawer>
  );
};

export default MobileNavMenu;
