import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification, Button } from "antd";

const useNotif = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  // Opens a cookie's notification if user didnt already accept conditions
  const openCookiesNotif = () => {
    // Checks if user already accepted
    const userConfirmed = sessionStorage.getItem("4acc");

    if (!userConfirmed) {
      // Allows to close specific notification
      const key = `open${Date.now()}`;
      const closeNotif = () => {
        // If user accepts we set cookie
        sessionStorage.setItem("4acc", "true");
        // notification is closed so we update state
        setIsOpen(false);
      };
      const btn = (
        <>
          <Button
            style={{ marginRight: "5px" }}
            type="primary"
            onClick={() => {
              navigate("/mentions-legales-et-politique-de-confidentialite");
            }}
          >
            En savoir plus
          </Button>
          <Button
            type="primary"
            onClick={() => {
              closeNotif();
              notification.close(key);
            }}
          >
            OK
          </Button>
        </>
      );

      // Opens notification
      notification.info({
        message: "Cookies",
        description:
          "Ce site utilise des cookies nécessaires pour son bon fonctionnement. En continuant votre navigation vous acceptez nos conditions d'utilisation.",
        placement: "bottomLeft",
        duration: 0,
        btn: btn,
        key,
        onClose: closeNotif,
      });
      // notification is open so we update state
      setIsOpen(true);
    }
  };

  return { openCookiesNotif, isOpen };
};

export default useNotif;
