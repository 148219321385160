import { memo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import SubMenu from "./SubMenu";

import { Dropdown } from "antd";
import { RightArrow, DownArrow } from "assets/icons";

const switchColor = (
  isActive: boolean,
  isTopScreen: boolean,
  location: string
) => {
  if (isActive) {
    return "var(--mainBlue)";
  }

  if (isTopScreen && location === "/") {
    return "white";
  }

  return "var(--mainGrey)";
};

const NavSubmenu = memo(
  ({ item, isTopScreen }: { item: any; isTopScreen: boolean }) => {
    const [arrowIcon, setArrowIcon] = useState(<RightArrow />);
    const location = useLocation().pathname;
    const isActive = location.split(`${item.path}`).length > 1;

    return (
      <li
        onMouseEnter={() => {
          setArrowIcon(<DownArrow />);
        }}
        onMouseLeave={() => {
          setArrowIcon(<RightArrow />);
        }}
      >
        <Dropdown overlay={() => SubMenu(item.children)}>
          <button
            className="navbar_button"
            style={{
              color: switchColor(isActive, isTopScreen, location),
              textDecoration: isActive ? "underline" : "none",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {item.title} {item.children && arrowIcon}
            </div>
          </button>
        </Dropdown>
      </li>
    );
  }
);

export default NavSubmenu;
