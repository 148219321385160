import { tNavData } from "types";

import {
  PayIcon,
  ValidBulletinIcon,
  ValidHrIcon,
  AuditIcon,
  HrIcon,
  CounselIcon,
  ExternalIcon,
  UrssafIcon,
  CourseIcon,
} from "assets/icons";

const navData: tNavData = {
  home: { key: "home", title: "Accueil", path: "/" },
  solutions: {
    key: "solutions",
    title: "Nos solutions",
    path: "/nos-solutions",
    children: [
      {
        key: "tpe",
        title: "TPE",
        extTitle:
          "Libérez-vous des activités chronophages, centrez-vous sur votre cœur de métier",
        text: "Vous avez peu de temps à dédier à l'administratif comme la réalisation des bulletins de paie",
        path: "/nos-solutions/tpe",
      },
      {
        key: "pme",
        title: "PME",
        extTitle: "Assurez-vous l’expertise au sein de l’entreprise",
        text: "Vous assurez souvent une partie de la gestion du personnel en interne, quelles compétences vous manque-t-il ?",
        path: "/nos-solutions/pme",
      },
      {
        key: "expert",
        title: "Expert comptable",
        extTitle: "Gérez simplement votre pôle social",
        text: "Difficile de trouver de nouveaux collaborateurs, comment assurer l’évolution du pôle social en fonction du mouvement de la clientèle ?",
        path: "/nos-solutions/expert-comptable",
      },
      {
        key: "lawyer",
        title: "Avocat en droit social",
        extTitle: "Augmentez votre expertise en technique de paie",
        text: "Vous maitrisez le droit social, mais qu'en est-il des techniques de paie ?",
        path: "/nos-solutions/cabinet-juridique",
      },
    ],
  },
  pay: {
    key: "pay",
    title: "Paie",
    path: "/paie",
    size: "cover",
    icon: PayIcon,
    alt: "bulletin de paie",
    children: [
      {
        key: "externalization",
        title: "Externalisation",
        extTitle: "Retrouvez votre cœur de métier, libérez-vous des risques",
        path: "/paie/externalisation",
        icon: ExternalIcon,
        alt: "",
      },
      {
        key: "internalPay",
        title: "Gestion interne Paie",
        extTitle:
          "Apportez un soutien à votre collaborateur en charge de la gestion du personnel",
        path: "/paie/gestion-interne",
        icon: AuditIcon,
        alt: "engrenage",
      },
      {
        key: "auditPay",
        title: "Audit de paie",
        extTitle:
          "La justesse des bulletins de salaire et des charges sociales est le premier élément pour des relations sereines avec les administrations et le personnel",
        path: "/paie/audit",
        icon: ValidBulletinIcon,
        alt: "bulletin de paie validé",
      },
    ],
  },
  hr: {
    key: "hr",
    title: "RH",
    path: "/rh",
    icon: HrIcon,
    alt: "personnel connecté au responsable RH",
    children: [
      {
        key: "internalHr",
        title: "Gestion Interne RH",
        extTitle:
          "Nous sommes à vos côtés pour vous guider et vous soutenir dans cette mission complexe",
        path: "/rh/gestion-interne-rh",
        icon: AuditIcon,
        alt: "engrenage",
      },
      {
        key: "auditHr",
        title: "Audit RH",
        extTitle:
          "La bonne gestion des ressources humaines d’une entreprise est un élément essentiel au bon fonctionnement de celle-ci",
        path: "/rh/audit",
        icon: ValidHrIcon,
        alt: "Gestion rh validée",
      },
    ],
  },
  counsel: {
    key: "counsel",
    title: "Conseil",
    path: "/conseil-et-accompagnement",
    icon: CounselIcon,
    alt: "échange de type question/réponse",
    children: [
      {
        key: "formation",
        title: "Formations sur mesure",
        extTitle:
          "La loi évolue et il n'est pas toujours facile de suivre tous les changements",
        path: "/conseil-et-accompagnement/formation",
        icon: CourseIcon,
        alt: "",
      },
      {
        key: "urssaf",
        title: "Contrôle URSSAF",
        path: "/conseil-et-accompagnement/URSSAF",
        icon: UrssafIcon,
        alt: "",
      },
    ],
  },
  about: {
    key: "about",
    title: "Qui sommes-nous ?",
    path: "/qui-sommes-nous",
    children: [
      {
        key: "team",
        title: "Notre équipe",
        path: "/qui-sommes-nous/equipe-4A",
      },
      {
        key: "values",
        title: "Nos valeurs",
        extTitle: "Sécuriser le social de votre entreprise",
        path: "/qui-sommes-nous/les-valeurs-4A",
      },
    ],
  },
  faq: {
    key: "faq",
    title: "FAQ",
    path: "/faq",
    children: [
      {
        key: "questions",
        title: "Questions fréquentes",
        path: "/faq/questions-frequentes",
      },
      { key: "pricing", title: "Nos tarifs", path: "/faq/tarifs" },
    ],
  },
  contact: {
    key: "Contact",
    title: "Un renseignement, une rencontre ?",
    extTitle: "Contactez-nous !",
    path: "/contact",
  },
  legal: {
    key: "legal",
    title: "Mentions légales et politique de confidentialité",
    path: "/mentions-legales-et-politique-de-confidentialite",
  },
};

export default navData;
