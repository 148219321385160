import NavSubmenuBtn from "./NavSubmenuBtn";
import NavBtn from "./NavBtn";

import navData from "data/navData";

import { useScroll } from "hooks";

const DesktopMenu = () => {
  const { home, solutions, pay, hr, counsel, about, faq } = navData;
  const { scrollY } = useScroll();

  return (
    <ul className="navbar_desktop_menu">
      <NavBtn
        key={`nav_btn_${home.key}`}
        item={home}
        isTopScreen={scrollY === 0}
      />
      {[solutions, pay, hr, counsel, about, faq].map((item) => (
        <NavSubmenuBtn
          key={`nav_btn_${item.key}`}
          item={item}
          isTopScreen={scrollY === 0}
        />
      ))}
    </ul>
  );
};

export default DesktopMenu;
