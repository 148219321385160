import { useEffect, useState, useCallback } from "react";

import { useLocation } from "react-router-dom";

const useScroll = () => {
  const document = window;
  const location: string = useLocation().pathname;

  // Value of current page vertical scroll
  const [scrollY, setScrollY] = useState<number>(document.scrollY);

  // Update state when called
  const handleScroll = useCallback(() => {
    setScrollY(document.scrollY);
  }, [document.scrollY]);

  // Update state when scrollY changes
  useEffect(() => {
    // Scroll listener
    window.addEventListener("scroll", () => handleScroll());
    // Remove scroll listener
    return () => window.removeEventListener("scroll", () => handleScroll());
  }, [document.scrollY, handleScroll]);

  useEffect(() => {
    // Scroll up to top page when location changes
    document.scrollY > 0 && document.scrollTo(0, 0);
  }, [location, document]);

  return { scrollY };
};

export default useScroll;
